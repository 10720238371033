<template>
  <div>
    <div>
      <div class="c-head">
        <div class="df1">
          <div @click="change(0)" class="box-btn bgc-3EA">

          </div>
          <div class="h20">
            今日
          </div>
        </div>
        <div class="df">
          <div @click="change(1)" class="box-btn bgc-956">

          </div>
          <div class="h20">
            历史
          </div>
        </div>
      </div>
    </div>
    <div style="height: 320px;width: 380px;z-index: -1">
      <Chart v-if="showChart" :cdata="cdata" />
    </div>
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props:{
    data:{
      type: Object
    }
  },
  data () {
    return {
      cdata: {
        xData: ["data1", "data2", "data3", "data4", "data5", "data6"],
        seriesData: []
      },
      showChart: false
    }
  },
  components: {
    Chart,
  },
  mounted () {
    this.cdata.seriesData[0] = {
      value: Math.abs((this.data.todayTotalIncome.toFixed(2)-this.data.todaySpendingATotal.toFixed(2)).toFixed(2)),
      name: '今日利润'
    }
    this.cdata.seriesData[1] = {
      value: this.data.todaySpendingATotal.toFixed(2),
      name: '今日支出'
    }
    this.showChart = true
  },
  methods: {
    change(type){
      this.showChart = false
      if(type === 0){
        this.cdata.seriesData[0] = {
          value: (this.data.todayTotalIncome.toFixed(2)-this.data.todaySpendingATotal.toFixed(2)).toFixed(2),
          name: '今日利润'
        }
        this.cdata.seriesData[1] = {
          value: this.data.todaySpendingATotal.toFixed(2),
          name: '今日支出'
        }
      }else{
        this.cdata.seriesData[0] = {
          value: (this.data.totalIncome.toFixed(2)-this.data.spendingATotal.toFixed(2)).toFixed(2),
          name: '历史利润'
        }
        this.cdata.seriesData[1] = {
          value: this.data.spendingATotal.toFixed(2),
          name: '历史支出'
        }
      }
      setTimeout(() =>{
        this.showChart = true
      },100)


    }
  }
}
</script>

<style lang="scss" scoped>
  .c-head{
    position: absolute;
    top: 30px;
    right: 0;
    display: flex;
  }
  .df{
    display: flex;
    justify-content: end;
    padding-right: 40px;
  }
  .df1{
    display: flex;
    justify-content: end;
    padding-right: 10px;
  }

  .box-btn{
    height: 20px;
    width: 40px;
    border-radius: 5px;
    line-height: 20px;
    cursor: pointer;
    margin-right: 5px;
    z-index: 100;
  }
  .h20{
    height: 20px;
    line-height: 20px!important;
  }
  .bgc-956{
    background: linear-gradient(to bottom,#ffce7b,#d5c59f);
  }
  .bgc-3EA{
    background: linear-gradient(to bottom,#7bbfea,#3EACE5);
  }
</style>
