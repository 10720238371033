<template>
  <div class="chartStyle" id="centreLeft1Chart">
<!--    <Echart-->
<!--      :options="options"-->
<!--      ref="centreLeft1Chart"-->
<!--      height="300px"-->
<!--      width="380px"-->
<!--    ></Echart>-->
  </div>
</template>

<script>
//import Echart from '@/common/echart'
var echarts = require('echarts/lib/echarts');
export default {
  data () {
    return {
      options: {},
    };
  },
  // components: {
  //   Echart,
  // },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {

      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getEchart()

  },
  methods:{
    getEchart() {
      let myChart = echarts.init(document.getElementById('centreLeft1Chart'));
      this.options = {
        color: [
          "#37a2da",
          "#ffdb5c",
          "#9fe6b8",
          "#ffdb5c",
          "#ff9f7f",
          "#fb7293",
          "#e7bcf3",
          "#8378ea"
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} 元",
          textStyle: { // 提示框浮层的文本样式。
            color: '#fff',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 16,
      },
        },
        toolbox: {
          show: true
        },
        grid: {
          left: '20',
          right: '50',
          bottom: '0',
          top: '45',
        },
        calculable: true,
        legend: {
          bottom: '30',
          left: '110',
          // right: 'center'
          textStyle: {
            color: "#fff",
            fontSize: '16'
          },
        },
        series: [
          {
            name: "收支比列",
            type: "pie",
            radius: [60, 100],
            center: ["50%", "40%"],
            itemStyle: {
              borderRadius: 8
            },
            data: this.cdata.seriesData
          }
        ]
      }
      myChart.setOption(this.options, true);

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      let lastIndex = 0;
      let currentIndex = 0;
      setInterval(() => {
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: lastIndex,
        });
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex,
        });
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: currentIndex,
        });
        lastIndex = currentIndex;
        ++currentIndex;
        if(currentIndex > 1) {
          currentIndex = 0;
        }
      }, 3000)
    }
  }
};
</script>

<style lang="scss" scoped>
  .chartStyle{
    width: 380px;
    height: 320px;
  }

</style>
