<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">

        <div class="d-flex">
          <div class='box-title mb10'>
            <el-popover
                    placement="bottom"
                    width="100%"
                    trigger="click"
                    v-model="showPopover"
                    transition="el-zoom-in-top"
            >
              <div style="padding: 20px">
                <div class="p15 tc f16">
                  时间筛选
                </div>
                <div>
                  <el-date-picker
                          v-model="value"
                          type="datetimerange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
                <div class="mt10 tr">
                  <button class="btn-reset " @click="timeConfirm(0)">
                    重置
                  </button>
                  <button class="btn-add" @click="timeConfirm(1)">
                    确定
                  </button>
                </div>
              </div>
              <!--          <el-button slot="reference">click 激活</el-button>-->
              <div slot="reference" class="box-i-time">
                <i  class="el-icon-time i-time"></i>
              </div>
            </el-popover>
            <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">{{title}}</dv-decoration-11></span>
          </div>
        </div>
      </div>
      <div class="body">
        <BottomLeftChart ref="blChart"  :screen-type="screenType"/>
      </div>
    </div>
  </div>
</template>

<script>
import BottomLeftChart from '../../../../components/bottomLeftChart'
import {formatTime} from "../../../../util/util";
export default {
  components: {
    BottomLeftChart
  },
  props:{
    screenType:{
      type: Number
    }
  },
  data() {
    return{
      title: 'e收银销售分析',
      value: '',
      startTime: '',
      endTime: '',
      showPopover: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  mounted() {
    if (localStorage.getItem("time2")) {
      let time2 = JSON.parse(localStorage.getItem('time2'))
      this.value = [time2.startTime,time2.endTime]
    }
  },
  methods:{
    // changeType(data){
    //   this.title = data === 0 ? '品类销售额' : '品类出库量'
    // },
    timeConfirm(type){
      if(type === 1){
        this.startTime = formatTime(this.value[0],'yyyy-MM-dd HH:mm:ss')
        this.endTime = formatTime(this.value[1],'yyyy-MM-dd HH:mm:ss')
      }else{
        this.startTime = ''
        this.endTime = ''
        this.value = ['','']
      }
      //this.getChart5()
      this.$refs.blChart.parentHandleclick({startTime: this.startTime,endTime: this.endTime});
      this.showPopover = false
    },
  }
}
</script>

<style lang="scss" scoped>
$box-height: 550px;
$box-width: 100%;
#bottomLeft {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
  .body{
    height: 550px;
  }

}
</style>
