var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"centerLeft1"}},[_c('div',{staticClass:"bg-color-black"},[_c('div',{staticClass:"d-flex pt-2 pl-2"},[_c('span'),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"box-title"},[_c('span',{staticClass:"fs-xl text mx-2"},[_c('dv-decoration-11',{staticStyle:{"width":"200px","height":"60px"}},[_vm._v("汇总数据")])],1)])])]),_c('div',{staticClass:"d-flex jc-center"}),(this.screenType === 0)?_c('div',{staticClass:"data-grid-0"},_vm._l((_vm.numberData1),function(item,index){return _c('div',{key:index,staticClass:"df aic jcc",staticStyle:{"flex-direction":"column"}},[_c('div',{staticStyle:{"font-size":"28px","line-height":"34px"}},[_c('countTo',{staticClass:"dv-digital-flop",attrs:{"startVal":_vm.start,"endVal":item.number ? item.number : 0,"duration":3000,"decimals":item.decimals}})],1),_c('div',{staticStyle:{"color":"#c3cbde","line-height":"16px"}},[_vm._v(" "+_vm._s(item.text)+" "),(
              item.text === '今日支出' ||
              item.text === '历史总支出' ||
              item.text === '历史总收入' ||
              item.text === '今日收入'
            )?_c('span',{staticClass:"colorYellow"},[_vm._v("(元)")]):_c('span',{staticClass:"colorYellow"},[_vm._v("(次)")])]),_c('div',{staticStyle:{"height":"0","line-height":"20px"}},[(item.text === '历史总支出')?_c('p',{staticClass:"grey"},[_vm._v(" (应付:"+_vm._s(_vm.data.gzIncome)+"元) ")]):_vm._e(),(item.text === '历史总收入')?_c('p',{staticClass:"grey"},[_vm._v(" (应收:"+_vm._s(_vm.data.gzSpending)+"元) ")]):_vm._e()])])}),0):_c('div',{staticClass:"df"},[_c('div',{staticClass:"data-grid"},_vm._l((_vm.numberData2),function(item,index){return _c('div',{key:index,staticClass:"df aic jcc",staticStyle:{"flex-direction":"column"}},[_c('div',{staticStyle:{"font-size":"28px","line-height":"34px"}},[_c('countTo',{staticClass:"dv-digital-flop",attrs:{"startVal":_vm.start,"endVal":item.number ? item.number : 0,"duration":3000,"decimals":item.decimals}})],1),_c('div',{staticStyle:{"color":"#c3cbde","line-height":"16px"}},[_vm._v(" "+_vm._s(item.text)+" "),(
                item.text === '今日支出' ||
                item.text === '历史总支出' ||
                item.text === '历史总收入' ||
                item.text === '今日收入'
              )?_c('span',{staticClass:"colorYellow"},[_vm._v("(元)")]):_c('span',{staticClass:"colorYellow"},[_vm._v("(次)")])]),_c('div',{staticStyle:{"height":"0","line-height":"20px"}},[(item.text === '历史总支出')?_c('p',{staticClass:"grey"},[_vm._v(" (应付:"+_vm._s(_vm.data.gzIncome)+"元) ")]):_vm._e(),(item.text === '历史总收入')?_c('p',{staticClass:"grey"},[_vm._v(" (应收:"+_vm._s(_vm.data.gzSpending)+"元) ")]):_vm._e()])])}),0),(!_vm.time.startTime)?_c('div',[_c('div',{staticClass:"d-flex jc-center"},[_c('CenterLeft1Chart',{attrs:{"data":_vm.data}})],1)]):_c('div',{staticClass:"bottom-data"},[_c('h1'),_c('h1'),_c('div',{staticClass:"item-box mt-2"},[_c('div',{staticClass:"d-flex"},[_c('countTo',{staticClass:"dv-digital-flop",attrs:{"startVal":_vm.start,"endVal":_vm.totalIncome ? _vm.totalIncome : 0,"duration":3000,"decimals":2}})],1),_vm._m(0)]),_c('div',{staticClass:"item-box mt-2"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dv-digital-flop"},[_vm._v(" "+_vm._s(_vm.time.startTime.split(" ")[0])+" ")])]),_vm._m(1)]),_c('div',{staticClass:"item-box mt-2"},[_c('div',{staticClass:"d-flex"},[_c('countTo',{staticClass:"dv-digital-flop",attrs:{"startVal":_vm.start,"endVal":_vm.spendingATotal ? _vm.spendingATotal : 0,"duration":3000,"decimals":2}})],1),_vm._m(2)]),_c('div',{staticClass:"item-box mt-2"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dv-digital-flop"},[_vm._v(" "+_vm._s(_vm.time.endTime.split(" ")[0])+" ")])]),_vm._m(3)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text",staticStyle:{"text-align":"center"}},[_vm._v(" 时段总收入 "),_c('span',{staticClass:"colorYellow"},[_vm._v("(元)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text",staticStyle:{"text-align":"center"}},[_vm._v(" 开始时间 "),_c('span',{staticClass:"colorYellow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text",staticStyle:{"text-align":"center"}},[_vm._v(" 时段总支出 "),_c('span',{staticClass:"colorYellow"},[_vm._v("(元)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text",staticStyle:{"text-align":"center"}},[_vm._v(" 结束时间 "),_c('span',{staticClass:"colorYellow"})])}]

export { render, staticRenderFns }