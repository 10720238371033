<template>
  <div class="body">
    <div class="c-head">
      <div class="df" style="">
        <div @click="changeType(0)" class="box-btn bgc-3EA"></div>
        <div>品类销售额</div>
        <div style="width: 100px;padding-left:10px">
          <div >{{ incomeTotal }}元</div>
        </div>
      </div>
      <div class="df pt5">
        <div @click="changeType(1)" class="box-btn bgc-956"></div>
        <div>品类出库量</div>
        <div style="width: 100px;padding-left:10px"></div>
      </div>
    </div>
    <dv-loading class="loading" v-show="loading"></dv-loading>
    <Chart v-if="show" :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import api from "../../util/extra-api";
export default {
  props: {
    screenType: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true,
      data: null,
      cdata: {
        category: [],
        lineData: [],
        barData: [],
        rateData: [],
        type: 0,
        end: 100,
      },
      startTime: "",
      endTime: "",
      show: false,
      incomeTotal: null,
      marketTotal: null,
    };
  },
  components: {
    Chart,
  },
  mounted() {
    if (localStorage.getItem("time2")) {
      let time2 = JSON.parse(localStorage.getItem("time2"));
      this.startTime = time2.startTime;
      this.endTime = time2.endTime;
    }
    this.getChart3();
  },
  methods: {
    parentHandleclick(e) {
      this.startTime = e.startTime;
      this.endTime = e.endTime;
      let time2 = {
        startTime: this.startTime,
        endTime: this.endTime,
      };
      localStorage.setItem("time2", JSON.stringify(time2));
      this.getChart3();
    },
    changeType(type) {
      this.$emit("changeType", type);
      if (type === 0) {
        this.cdata.category = this.data.incomeRankList.map((m) => m.goodsName);
        this.cdata.barData = this.data.incomeRankList.map((m) => m.totalMoney);
        this.cdata.type = type;
      } else {
        this.cdata.category = this.data.marketRankList.map((m) => m.goodsName);
        this.cdata.barData = this.data.marketRankList.map((m) => m.count);
        this.cdata.type = type;
      }
    },
    getChart3() {
      this.loading = true;
      this.show = false;
      let data = {
        companyId: localStorage.getItem("company_id"),
        type: localStorage.getItem("type"),
        startTime: this.startTime,
        endTime: this.endTime,
      };
      api.get("/v1/wx/companyScreen/chart3", data).then((res) => {
        if (res.code === 200) {
          this.data = res.data;
          this.incomeTotal = res.data.incomeTotal;
          this.marketTotal = res.data.marketTotal;
          if (this.screenType == 1) {
            if (this.data.incomeRankList.length >= 10) {
              this.cdata.end = (10 / this.data.incomeRankList.length) * 100;
            } else {
              this.cdata.end = 100;
            }
          } else {
            if (this.data.incomeRankList.length >= 20) {
              this.cdata.end = (20 / this.data.incomeRankList.length) * 100;
            } else {
              this.cdata.end = 100;
            }
          }
          this.cdata.category = this.data.incomeRankList.map(
            (m) => m.goodsName
          );
          this.cdata.barData = this.data.incomeRankList.map(
            (m) => m.totalMoney
          );
          this.loading = false;
          this.show = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.c-head {
  position: absolute;
  top: 30px;
  right: 0;
}
.df {
  display: flex;
  justify-content: end;
  padding-right: 20px;
}

.box-btn {
  height: 20px;
  width: 40px;
  border-radius: 25px;
  line-height: 20px;
  cursor: pointer;
}
.bgc-956 {
  background-color: #956fd4;
}
.bgc-3EA {
  background-color: #3eace5;
}
.body {
  height: 550px;
}
</style>
